import api from "../api";

export default {
  namespaced: true,
  state: {
    products: [
      // {
      //   reference,
      //   endDate,
      //   name,
      //   description,
      //   images,
      // }
    ],
    details: {
      volets: "",
      menuiserie: "",
      stationnement: "",
      electricite: "",
      etage: "",
      precision: "",
    },
    references: [],
  },
  mutations: {
    reset(state) {
      state.products = [];
    },
    addProduct(state, product) {
      state.products = [...state.products, product];
    },
    setDescriptionOfProduct(state, { index, description }) {
      const products = [...state.products];
      products[index].description = description;
      state.products = products;
    },
    setImagesOfProduct(state, { index, images }) {
      const products = [...state.products];
      products[index].images = images;
      state.products = products;
    },
    removeImageOfProduct(state, { index, image }) {
      const products = [...state.products];
      products[index].images = products[index].images.filter(
        ({ response }) => response !== image.response
      );
      state.products = products;
    },
    removeProduct(state, productIndex) {
      state.products = [
        ...state.products.slice(0, productIndex),
        ...state.products.slice(productIndex + 1),
      ];
    },
    setDetails(state, { name, value }) {
      state.details = { ...state.details, [name]: value };
    },
    setReferences(state, references) {
      state.references = references;
    },
  },
  actions: {
    reset({ commit }) {
      commit("reset");
    },
    removeProduct({ commit }, index) {
      commit("removeProduct", index);
    },
    setDescriptionOfProduct({ commit }, data) {
      commit("setDescriptionOfProduct", data);
    },
    setImagesOfProduct({ commit }, data) {
      commit("setImagesOfProduct", data);
    },
    async removeImageOfProduct(
      { state, commit },
      { index, image },
      action = "intervention"
    ) {
      if (!image.path) return false;
      const { data: res } = await api.post(`/intervention/removeUpload`, {
        file: image.path,
      });
      commit("removeImageOfProduct", { index, image });
      return !!res;
    },
    async validateReference({ commit }, reference) {
      let productInfos = {
        reference,
        name: "(base de données garanties indisponible)",
        endDate: null,
      };
      try {
        const { data } = await api.post(
          `/intervention/validate`,
          JSON.stringify(reference),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        productInfos = data;
        if (!productInfos) return false;
      } catch (err) {}

      commit("reset"); // Un seul produit à la fois
      commit("addProduct", { ...productInfos, description: "", images: [] });
      return productInfos;
    },
    setDetails({ commit }, data) {
      commit("setDetails", data);
    },
    async submit({ state, commit }, { customer, action = "intervention" }) {
      const { data } = await api.post(
        `/${action}/submit`,
        {
          product: state.products[0],
          details: state.details,
          customer,
        },
        {
          timeout: 30000, // 30 sec for emails
        }
      );

      commit("setReferences", data.activatedWarranties);
      if (!!data.success) commit("reset");
      return !!data.success;
    },
    async submitContactIntervention({ state, commit }, contactForm) {
      const { data: form } = await api.post(
        "/intervention/sendcontact",
        {
          form: contactForm,
        },
        {
          timeout: 30000, // 30 sec for emails
        }
      );
      return !!form;
    },
  },
};
