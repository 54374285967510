import _difference from "lodash/difference";

export default {
  namespaced: true,
  state: {
    selection: {
      type: "",
      reference: ""
    }
  },
  mutations: {
    setSelection(state, { name, value = "" }) {
      state.selection[name] = value;
    }
  },
  actions: {
    setSelection({ commit }, data) {
      Object.keys(data).forEach(name =>
        commit("setSelection", { name, value: data[name] })
      );
    }
  }
};
