import api from "../api";

export default {
  state: {
    alive: true,
    customer: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      street: "",
      zipcode: "",
      city: "",
      country: "France",
    },
  },
  mutations: {
    setAlive(state, alive) {
      state.alive = alive;
    },
    setCustomerInfo(state, { name, value = "" }) {
      state.customer[name] = value;
    },
  },
  actions: {
    async checkAlive({ commit }) {
      try {
        const { data: alive } = await api.get("/test");
        commit("setAlive", alive);
      } catch (err) {
        commit("setAlive", false);
      }
    },
    setCustomerInfo({ commit }, data) {
      Object.keys(data).forEach((name) =>
        commit("setCustomerInfo", { name, value: data[name] })
      );
    },
  },
};
