import _difference from "lodash/difference";

import api from "../api";

export default {
  namespaced: true,
  state: {
    codes: {
      isMultiple: false,
      single: "",
      multiple: "",
    },
    references: [],
    registered: [],
    contactForm: { fullName: null },
  },
  mutations: {
    reset(state) {
      state.codes = {
        isMultiple: false,
        single: "",
        multiple: "",
      };
      state.references = { valid: [], invalid: [], registered: [] };
    },
    setCode(state, code) {
      state.codes = { ...state.codes, single: code, isMultiple: false };
    },
    setCodes(state, codes) {
      state.codes = { ...state.codes, multiple: codes, isMultiple: true };
    },
    setReferences(state, references = []) {
      state.references = references;
    },
    setRegistered(state, references = []) {
      state.registered = references;
    },
  },
  actions: {
    reset({ commit }) {
      commit("reset");
    },
    switchMultipleCode({ state, commit }) {
      if (state.codes.isMultiple) {
        commit(
          "setCode",
          state.codes.single || state.codes.multiple.split("\n")[0]
        );
      } else {
        commit("setCodes", state.codes.multiple || state.codes.single);
      }
    },
    setCode({ commit }, code) {
      commit("setCode", code);
    },
    setCodes({ commit }, codes) {
      commit("setCodes", codes);
    },
    async validateReference({ commit }, reference) {
      const { data } = await api.post("/registration/validate", [reference]);
      commit("setReferences", data);
      return !!data.length;
    },
    async setExcludeReference({ state, commit }, { reference, exclude }) {
      commit(
        "setReferences",
        state.references.map((ref) => {
          if (ref.reference !== reference) return ref;
          return { ...ref, exclude };
        })
      );
    },
    async registerWarranty({ state, commit }, customer) {
      const { data: references } = await api.post(
        "/registration/register",
        {
          references: state.references
            .filter(({ exclude, isValid }) => !!isValid && !exclude)
            .map(({ reference }) => reference),
          customer,
        },
        {
          timeout: 30000, // 30 sec for emails
        }
      );
      commit("setRegistered", references);
      return !!references.length;
    },
    async submitContactRegistration({ state, commit }, contactForm) {
      const { data: form } = await api.post(
        "/registration/sendcontact",
        {
          form: contactForm,
        },
        {
          timeout: 30000, // 30 sec for emails
        }
      );
      return !!form;
    },
  },
};
