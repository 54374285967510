import _difference from "lodash/difference";
import api from "../api";

export default {
  namespaced: true,
  state: {
    list: [],
    error: null
  },
  mutations: {
    setList(state, list = []) {
      state.list = list;
    },
    setError(state, error = null) {
      state.error = error;
    }
  },
  actions: {
    async validateReference({ commit }, reference) {
      try {
        const { data } = await api.get("/notices/find/" + reference);
        commit("setList", data);
      } catch ({ response: { data: { error: { message } = {} } = {} } = {} }) {
        if (message === "no-product") return false;
        commit("setError", message);
      }

      return true;
    }
  }
};
