import Vue from "vue";
import Vuex from "vuex";
import intervention from "./stores/intervention";
import notices from "./stores/notices";
import registration from "./stores/registration";
import root from "./stores/root";
import selection from "./stores/selection";

Vue.use(Vuex);

export default new Vuex.Store({
  ...root,
  modules: {
    registration,
    intervention,
    notices,
    selection
  }
});
