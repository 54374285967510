import "./sass/reset.scss";
import "./sass/app.scss";

import App from "./components/App.vue";
import Vue from "vue";
import VueHead from "vue-head";
import VueRouter from "vue-router";
import store from "./stores";

Vue.use(VueHead, {
  title: "Garantie 5 ans",
  separator: "-",
  complement: "Eveno Fermetures"
});
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("./components/MainLayout.vue"),
    children: [
      { path: "/", component: () => import("./pages/index.vue") },
      {
        path: "/selection",
        component: () => import("./pages/selection.vue")
      },
      {
        path: "/enregistrement",
        component: () => import("./pages/registration.vue")
      },
      {
        path: "/intervention",
        component: () => import("./pages/intervention.vue")
      },
      {
        path: "/hors-garantie",
        component: () => import("./pages/spareparts.vue")
      },
      {
        path: "/mentions-legales",
        component: () => import("./pages/legal.vue")
      }
    ]
  },
  {
    path: "/notices",
    component: () => import("./components/Page.vue"),
    children: [
      {
        path: "",
        component: () => import("./pages/notices.vue")
      },
      {
        path: "selection",
        component: () => import("./pages/selection.vue")
      }
    ]
  }
];
const router = new VueRouter({
  mode: "history",
  routes
});

new Vue({
  el: "body",
  router,
  store,
  render: h => h(App)
});
